import { IApiStyle } from 'api/entities/ApiForm';
import { IFontStyles } from 'state/slices/stylesSlice/types';
import { toMap } from './globalStylesAdapter';

export const fontStylesAdapter = (apiStyles: IApiStyle[]) => {
  const apiStyleMap = toMap(apiStyles);
  const fontFamAndUrl = apiStyleMap.get('font_family')?.split('|||');
  const fontStyles: IFontStyles = {
    fontFamily: fontFamAndUrl?.[0],
    fontUrl: fontFamAndUrl?.[1],
    // fontWeight:
    //   apiStyleMap.get('font_weight_bold') === 'true' ? 'bold' : 'normal',
    isFontWeightBold: apiStyleMap.get('font_weight_bold') === 'true',
  };
  return fontStyles;
};
