import { IApiPage } from 'api/entities/ApiForm';
import { useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { fetchFormBySlug } from 'state/thunk';
import { IFormField } from './types';
import { mapPagesToFormFields } from './utils';
import { useFieldOptions } from 'hooks/options/useOptions';
import { useFieldStyles } from 'hooks/styles/useStyles';
import { TFieldOption } from 'state/slices/optionSlice/types';

type TUseForm = {
  selectedPage?: IApiPage;
  currentFormFields: { [key: string]: IFormField };
  isSubmit: boolean;
  isLoading: boolean;
  statusCode?: number;
};

export const useForm = (
  selectedLanguage: string,
  fraudKey: string,
  fraud: boolean,
): TUseForm => {
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { page, pagesHistory } = useAppSelector((state) => state.navigation);
  const { localization, statusCode, isLoading } = useAppSelector(
    (state) => state.formV2,
  );
  const storedThunks = JSON.parse(localStorage.getItem(fraudKey) as string);

  useEffect(() => {
    dispatch(fetchFormBySlug({ slug: slug ?? '', navigate }));
  }, []);

  const selectedFormLocalization = useMemo(
    () => localization.find((loc) => {
      if (selectedLanguage) {
        return (
          loc.language.name.toLowerCase() === selectedLanguage.toLowerCase()
        );
      }
      return loc.isDefault;
    }),
    [localization, statusCode, selectedLanguage],
  );

  const selectedPage = useMemo(() => {
    const currPage = selectedFormLocalization?.pages?.find(
      (e) => e?.id === page,
    )!;
    return {
      ...currPage,
      fields: currPage?.fields?.map((e) => ({ ...e, id: `${e?.id}str` })),
    };
  }, [selectedFormLocalization, page, selectedLanguage]);

  const currentFormFields: { [key: string]: IFormField } = useMemo(() => {
    const fields: { [key: string]: IFormField } = {};
    mapPagesToFormFields(localization ?? [], selectedLanguage)?.forEach(
      (field) => {
        if (field) {
          fields[`${field?.id}str`] = field;
        }
      },
    );
    return fields;
  }, [localization, selectedLanguage]);

  const isSubmit = useMemo(() => {
    if (selectedPage) {
      const currentPage = selectedPage?.options?.find((opt) => opt.key === 'is_submit')?.value
        === 'true';
      const nextPage = selectedFormLocalization?.pages
        .find((page) => page.id === selectedPage.id)
        ?.options?.find((opt) => opt.key === 'is_submit')?.value === 'true';
      return !!nextPage || !!currentPage;
    }
    return false;
  }, [selectedPage]);

  return {
    // @ts-ignore
    selectedPage: fraud ? storedThunks.thanksPage : selectedPage,
    currentFormFields,
    isSubmit,
    isLoading,
    statusCode,
  };
};

export const useDynamicMascot = (values: {
  [key: string]: IFormField;
}): string | undefined => {
  const options = useFieldOptions();
  const fieldStyles = useFieldStyles();
  const fieldOptions: [string, TFieldOption | undefined][] = Array.from(
    options.keys(),
  ).map((key, index) => [key, options.get(key)]);
  const isMascot = fieldOptions.find((e) => e[1]?.changeMascot);
  const mascots = isMascot ? fieldStyles.get(+isMascot![0])?.mascot : [];
  const fieldWithMascot = values[`${isMascot?.[0]}str`];
  const selectedMascot = fieldWithMascot
    ? mascots?.[+fieldWithMascot.answer + 1]
    : undefined;
  return selectedMascot;
};

export const useDynamicBackground = (values: { [key: string]: IFormField }) => {
  const options = useFieldOptions();
  const fieldStyles = useFieldStyles();
  const fieldOptions: [string, TFieldOption | undefined][] = Array.from(
    options.keys(),
  ).map((key, index) => [key, options.get(key)]);
  const isBackground = fieldOptions.find((e) => e[1]?.changeBackground);
  const backgrounds = isBackground
    ? fieldStyles.get(+isBackground![0])?.backgroundColor
    : [];
  const fieldWithMascot = values[`${isBackground?.[0]}str`];
  const selectedBackground = backgrounds?.[+(fieldWithMascot?.answer ?? -1) + 1];
  return selectedBackground;
};
