import { ISubmitButtonStyles } from 'state/slices/stylesSlice/types';
import { toMap } from './globalStylesAdapter';
import { IApiStyle } from 'api/entities/ApiForm';

export const submitButtonStylesAdapter = (apiStyles: IApiStyle[]) => {
  const apiStyleMap = toMap(apiStyles);
  const submitButtonStyles: ISubmitButtonStyles = {
    default: {
      backgroundColor: apiStyleMap
        .get('send_button_background_color')
        ?.split('|||') ?? ['var(--root-black)', 'var(--root-black)'],
      color: apiStyleMap.get('send_button_text_color')?.split('|||') ?? [
        'var(--root-white)',
        'var(--root-white)',
      ],
    },
    disabled: {
      backgroundColor: apiStyleMap
        .get('send_button_disabled_background_color')
        ?.split('|||') ?? ['var(--root-black)', 'var(--root-black)'],
      color: apiStyleMap
        .get('send_button_disabled_text_color')
        ?.split('|||') ?? ['var(--root-white)', 'var(--root-white)'],
    },
  };
  return submitButtonStyles;
};
