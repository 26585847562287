import React, { useEffect, useState } from 'react';
import { NumberScaleStyles } from './NumberScaleStyles';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { ColorScaleProps } from './types';
import { useFontStyles } from 'hooks/styles/useStyles';

export function NumberScale({
  numbersColor,
  selectedButtonColor,
  selectedNumberColor,
  defaultValue,
  signatures,
  errorName,
  value,
  handleChange,
  scaleValues,
  showDefaultValue,
  signatureColor,
}: ColorScaleProps) {
  const { touched } = useFormikContext();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const [assessment, setAssessment] = useState<number>();
  const scaleValueCondition = value || value === 0;

  useEffect(() => {
    if (showDefaultValue) {
      if (handleChange) {
        handleChange(defaultValue);
      } else {
        setAssessment(defaultValue);
      }
    }
  }, [defaultValue]);

  return (
    <NumberScaleStyles
      signatureColor={signatureColor}
      isFontWeightBold={isFontWeightBold}
    >
      <div className="buttons" style={{ borderColor: selectedButtonColor }}>
        {scaleValues?.map((number) => (
          <button
            key={number}
            type="button"
            style={
              (scaleValueCondition ? value === number : assessment === number)
                ? {
                  backgroundColor: selectedButtonColor,
                  borderColor: selectedButtonColor,
                }
                : { borderColor: selectedButtonColor }
            }
            onClick={
              handleChange
                ? () => handleChange(number)
                : () => setAssessment(number)
            }
          >
            <span
              style={
                (scaleValueCondition ? value === number : assessment === number)
                  ? { color: selectedNumberColor }
                  : { color: numbersColor }
              }
            >
              {number}
            </span>
          </button>
        ))}
      </div>

      <div className="signatures">
        <span>{signatures[0]}</span>
        <span>{signatures[1]}</span>
      </div>

      <div className="formErrorContainer">
        {/* {touched && (
          <ErrorMessage name={errorName} component={FormErrorMessage} />
        )} */}
        {/* {error && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
          <p aria-live="polite" tabIndex={0} aria-label={`Помилка ${error}`}>
            {error}
          </p>
        )} */}
      </div>
    </NumberScaleStyles>
  );
}
