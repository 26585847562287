import { mapApiExtraFieldToIExtraField } from 'adapters/form';
import { IApiLocalizations, IApiPage } from 'api/entities/ApiForm';
import { TFieldType } from 'entities/IExtraField';
import { IFormField } from './types';

export const getFieldDefaultAnswer = (type: TFieldType) => {
  const defaultValues: { [key: string]: any } = {
    title: '',
    name: '',
    email: '',
    phone_number: '',
    extra_question: '',
    checkbox: [''],
    radio: [''],
    scale_color: 1,
    scale_number: 1,
    scale_star: 0,
    hidden: '',
    upload_files: '',
    networks: [
      {
        // countryCodeNotEditable
        key: 'telegram',
        value: 'telegram',
        phoneInputRequired: true,
        name: 'telegram',
      },
    ],
    document: '',
    nps: '',
    quantity: '',
  };

  return defaultValues[type];
};

export const extractFields = (data: IApiPage[], all?: boolean) => data.reduce(
  (acc: any[], curr: IApiPage) => acc.concat(
    curr.fields
      ?.filter(
        (e) => all
              || (e.type !== 'caption'
                && e.type !== 'picture'
                && e.type !== 'paragraph'),
      )
      .map((e, index) => ({
        ...mapApiExtraFieldToIExtraField(e),
        answerPosition: index + 1,
      })),
  ),
  [],
);
export const mapPagesToFormFields = (
  localization: IApiLocalizations[],
  lang: string,
): IFormField[] | undefined => {
  const pages = (
    localization.find((e) => e.language.name === lang)
    ?? localization.find((e) => e.isDefault)
  )?.pages;
  if (pages?.length) return extractFields(pages ?? []);
};
