import { OptionState } from './types';

export const initialState: OptionState = {
  phone: '',
  sessionID: '',
  smsVerifyEnabled: false,
  globalOptions: {},
  formOptions: {},
  fieldOptions: new Map(),
};
