import React from 'react';

export function QuantityMinusIcon({ width = 18, height = 18, color = 'var(--root-white)' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 7 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.00568 0.684659V2.09091H0.278409V0.684659H6.00568Z"
        fill={color}
      />
    </svg>
  );
}
