import { SettingsStateV2 } from './types';

export const initialState: SettingsStateV2 = {
  sessionId: '',
  companyID: undefined,
  googlePlaceId: undefined,
  node: {
    nodeID: undefined,
    nodeAddress: '',
    showNodeCode: false,
    nodeCode: undefined,
  },
  form: {
    id: undefined,
    title: undefined,
    name: '',
    moscotUrl: undefined,
    logo: '',
    logoLocation: '',
    isPageByPageForm: false,
    sbmBtnText: '',
    fraudHours: 0,
  },
  anonymous: false,
  withStep: false,
};
