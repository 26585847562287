import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlug } from '../../thunk';
import { globalStylesAdapter } from 'adapters';
import { fieldStylesAdapter } from 'adapters/styles/fieldStylesAdapter';
import { formStylesAdapter } from 'adapters/styles/formStylesAdapter';
import { fontStylesAdapter } from 'adapters/styles/fontStylesAdapter';
import { submitButtonStylesAdapter } from 'adapters/styles/submitBtnStylesAdapter';

export const stylesSlice = createSlice({
  name: 'styles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFormBySlug.fulfilled, (state, action) => {
      const globalStyles = globalStylesAdapter(
        action.payload.nodeForm.form.styles,
      );
      const fieldStyles = fieldStylesAdapter(
        action.payload.nodeForm.form.localizations[0].pages,
      );
      const fontStyles = fontStylesAdapter(action.payload.nodeForm.form.styles);
      const submitButtonStyles = submitButtonStylesAdapter(
        action.payload.nodeForm.form.styles,
      );
      const formStyles = formStylesAdapter(action.payload);

      state.formStyles = formStyles;
      state.fieldStyles = fieldStyles;
      state.fontStyles = fontStyles;
      state.globalStyles = globalStyles;
      state.submitButtonStyles = submitButtonStyles;
    });
  },
});

const { actions, reducer } = stylesSlice;
// Extract and export each action creator by name
// export const {} = actions;
// Export the reducer, either as a default or named export
export default reducer;
