import React, { useMemo, useRef } from 'react';
import { SubmitButtonStyles } from './SubmitButtonStyles';
import { useAppSelector } from 'state/hooks';
import { ErrorIcon } from 'assets/icons/ErrorIcon';
import { translations } from 'i18n/translations';
import { SubmitButtonProps } from './types';
import { Loader } from 'components/loader';
import { useFontStyles } from 'hooks/styles/useStyles';
import { useFormikContext } from 'formik';
import { IFormState } from 'pages/form/types';

export const SubmitButton: React.FC<SubmitButtonProps> = ({
  children,
  extraBlockStyles,
  extraButtonStyles,
  onClick,
  bgColor,
  textColor,
  type,
  isLoading,
  isError,
  disabled,
  isPageByPageForm,
  form,
  selectedLanguage,
  selectedPage,
}) => {
  const { elementsColor } = useAppSelector((state) => state.form);
  const { isFontWeightBold, fontFamily } = useFontStyles();
  const { errors } = useFormikContext<IFormState>();

  const errorAnchor = useMemo(() => {
    const errorKeys = errors.extraFields
      ? Object.keys(errors.extraFields as object)
      : [];
    const errorIds = selectedPage?.fields.map((el) => `${el.id}`) ?? [];

    const hasError = errorIds.some((id) => errorKeys.includes(id));

    return hasError;
  }, [selectedPage, errors]);

  const btnRef = useRef<HTMLButtonElement | null>(null);
  const isLongText = children === translations[selectedLanguage!]?.skip;
  const onFocusError = () => {
    const errorElement = document.querySelector(
      `.page-${selectedPage?.id} .formErrorContainer p`,
    );
    if (errorElement instanceof HTMLElement) {
      errorElement.focus();
    }
  };

  const onPress = () => {
    setTimeout(() => {
      btnRef.current?.blur();
      if (errorAnchor) {
        onFocusError();
      } else {
        document.getElementById('question')?.focus();
      }
    }, 100);
    onClick!();
  };
  return (
    <SubmitButtonStyles
      fontFamily={fontFamily}
      style={extraBlockStyles}
      bgColor={bgColor || '#0E9285'}
      textColor={textColor || '#0E9285'}
      elementsColor={elementsColor}
      isFontWeightBold={isFontWeightBold}
    >
      <button
        ref={btnRef}
        aria-label={children ?? 'Далі'}
        form={form}
        disabled={disabled}
        /* eslint-disable-next-line react/button-has-type */
        type={type || 'submit'}
        style={extraButtonStyles}
        onClick={onPress}
      >
        <div
          className="statusWrapper"
          style={{ width: `${!isLongText ? '40px' : '0px'}` }}
        >
          {isLoading && <Loader height={20} color="#FFF" margin={0} />}
          {isError && (
            <div className="errorWrapper">
              <ErrorIcon color="red" />
            </div>
          )}
        </div>
        {children}
        <div
          className="statusWrapper"
          style={{ width: `${!isLongText ? '40px' : '0px'}` }}
        />
      </button>
    </SubmitButtonStyles>
  );
};
