import { ArrowDownIcon } from 'assets/icons';
import { useState, FC, useRef } from 'react';
import { useAppDispatch } from 'state/hooks';
import { getFlagUrl } from 'utils/getFlagUrl';
import { ILanguage } from 'entities/ILanguage';
import { useClickOutside } from 'hooks/useClickOutside';
import { useFormikContext } from 'formik';
import { IFormState } from 'pages/form/types';
import { changeStartPage } from 'state/slices/navigationSlice';
import { Container } from './styles';
import { TLanguageSelectorProps } from './types';
import { useLanguage } from 'hooks/language/useLanguage';
import { setSelectedLanguage } from 'state/slices/language';

export const LanguageSelector: FC<TLanguageSelectorProps> = () => {
  const dispatch = useAppDispatch();
  const selectedValueFieldRef = useRef<HTMLDivElement>(null);
  const optionsListRef = useRef<HTMLDivElement>(null);
  const [openLanguagesList, setOpenLanguagesList] = useState<boolean>(false);
  const { availableLanguages, language } = useLanguage();
  const { resetForm } = useFormikContext<IFormState>();
  const selectedLanguage = (language?.name ?? 'ua').toUpperCase();

  const setLanguage = (lang: ILanguage) => {
    dispatch(setSelectedLanguage(lang));
    dispatch(changeStartPage({ lang: lang.name }));
    resetForm();
  };

  useClickOutside(
    optionsListRef,
    () => {
      setOpenLanguagesList(false);
    },
    selectedValueFieldRef.current,
  );

  return (
    <Container>
      <div className="languageSelect" ref={selectedValueFieldRef}>
        {availableLanguages && availableLanguages?.length > 1 && (
          <button
            className={
              openLanguagesList ? 'selectedLanguage opened' : 'selectedLanguage'
            }
            type="button"
            onClick={() => setOpenLanguagesList(!openLanguagesList)}
          >
            {selectedLanguage && (
              <>
                <img src={getFlagUrl(selectedLanguage.toLowerCase())} alt="" />
                <span>{selectedLanguage}</span>
              </>
            )}
            <ArrowDownIcon color="#000" />
          </button>
        )}
        {availableLanguages && openLanguagesList && (
          <div className="languages" ref={optionsListRef}>
            {availableLanguages.map((lang) => (
              <button
                key={lang.id}
                type="button"
                onClick={() => {
                  // setSelectedLanguage(lang.name);
                  // dispatch(setFormLanguage(lang.name));
                  setLanguage(lang);
                  setOpenLanguagesList(false);
                }}
                className={
                  selectedLanguage === lang.name
                    ? 'language active'
                    : 'language'
                }
              >
                <img src={getFlagUrl(lang.name.toLowerCase())} alt="" />
                <span>{lang.name}</span>
              </button>
            ))}
          </div>
        )}
      </div>
    </Container>
  );
};
