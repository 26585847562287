import React from 'react';

export function QuantityPlusIcon({ width = 18, height = 18, color = 'var(--root-white)' }) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.21591 9.10511V0.667613H5.64773V9.10511H4.21591ZM0.713068 5.60227V4.17045H9.15057V5.60227H0.713068Z"
        fill={color}
      />
    </svg>
  );
}
