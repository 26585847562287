import { IApiStyle } from 'api/entities/ApiForm';
import { IGlobalStylesV2 } from 'state/slices/stylesSlice/types';

export function toMap(
  array: { key: string; value: string }[],
): Map<string, string> {
  const map = new Map();
  array.forEach((e) => {
    map.set(e.key, e.value);
  });
  return map;
}

export function globalStylesAdapter(apiStyles: IApiStyle[]): IGlobalStylesV2 {
  const apiStyleMap = toMap(apiStyles);
  const globalStyles: IGlobalStylesV2 = {
    defaultMascot: apiStyleMap.get('default_mascot')?.split('|||')[1],
    dividingLine: {
      color:
        apiStyleMap.get('dividing_decorative_line_color')?.split('|||')[1]
        ?? 'transparent',
      isShown: !!apiStyleMap.get('show_dividing_decorative_line'),
      width_in_percent: +(
        apiStyleMap.get('dividing_decorative_line_width_in_percent') ?? 0
      ),
      height: +(apiStyleMap.get('dividing_decorative_line_height') ?? 0),
    },
    // {"" => "Division color|||#000"}, {"" => "true"}, {"" => "90"}, {"" => "2"}},
    backgroundColor: apiStyleMap.get('background_color')?.split('|||'),
    logoLocation: apiStyleMap.get('logoLocation'),
    logoSelected: apiStyleMap.get('logo_selected')?.split('|||')[1],

    startPageButtonPercentWidth: apiStyleMap.get(
      'start_page_button_percent_width',
    ),
    isTransparentBg: apiStyleMap.get('transparent_bg') === 'true',
    notCheckedElementsColor: apiStyleMap
      .get('not_checked_elements_color')
      ?.split('|||'),
    elementsColor: apiStyleMap.get('elements_color'),
    radioButtonBorderWidth: apiStyleMap.get('radio_button_border_width'),
    radioButtonCheckedBorderRadius: apiStyleMap.get(
      'radio_button_checked_border_radius',
    ),
    radioButtonCheckedSizes: apiStyleMap.get('radio_button_checked_sizes'),
    notRadioCheckedElementsColor: apiStyleMap.get(
      'not_radio_checked_elements_color',
    )?.[0],
    stepsTextUnderlineColor: apiStyleMap.get(
      'steps_text_under_line_color',
    )?.[0],
    stepsTextColor: apiStyleMap.get('steps_text_color')?.[0],
  };

  return globalStyles;
}
