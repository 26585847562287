import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlug } from '../../thunk';
import { submitForm } from 'state/thunk/formThunkV2';

export const formSliceV2 = createSlice({
  name: 'formV2',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchFormBySlug.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(
      fetchFormBySlug.fulfilled,
      (state, { payload: { nodeForm } }) => {
        const userLang = (
          navigator.language // @ts-ignore
          || navigator.userLanguage
          || 'EN-GB'
        )
          .split('-')[1]
          ?.toLowerCase();

        const startPageId = nodeForm.form.localizations.find(
          (loc) => loc?.language?.name?.toLowerCase() === userLang,
        )?.pages[0].id
          ?? nodeForm.form.localizations.find((loc) => loc.isDefault)?.pages[0]
            .id!;
        const startPage: { [key: string]: number } = {};

        nodeForm.form.localizations.forEach((loc) => {
          startPage[loc.language.name] = loc.pages[0].id;
        });

        // devMode === true
        // console.log(
        //   nodeForm.form.localizations?.[0]?.pages.map((page) => ({
        //     id: page.id,
        //     nextPage: page.nextPageID,
        //     transitions: page.fields
        //       ?.find((field) => field?.answers?.[0]?.transitTo)
        //       ?.answers.map((e) => e.transitTo),
        //   })),
        // );
        state.isLoading = false;
        state.statusCode = 200;
        state.node = nodeForm.node;
        state.localization = nodeForm.form.localizations;
        state.formSettings = nodeForm.form;
        state.page = startPageId;
        state.pagesHistory = [startPageId];
        state.startPage = startPage;
        state.isPageByPageForm = nodeForm.form.localizations?.[0]?.pages?.length > 2;
      },
    );
    builder.addCase(fetchFormBySlug.rejected, (state, action) => {});
    builder.addCase(submitForm.fulfilled, (state, action) => {
      const nextPageID = state.localization[0].pages.find(
        (current_page) => current_page.id === state.page,
      )?.nextPageID;
      state.page = nextPageID!;
    });
    builder.addCase(submitForm.rejected, (state, action) => {});
  },
});

const { actions, reducer } = formSliceV2;
// Extract and export each action creator by name
// export const {  } = actions;
// Export the reducer, either as a default or named export
export default reducer;
