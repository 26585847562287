import { IApiAnswear, IApiField } from 'api/entities/ApiForm';
import { ICheckboxAnswer } from 'api/mappers';
import { IExtraField, IOption } from 'entities/IExtraField';
import { translations } from 'i18n/translations';
import _, { omit } from 'lodash';

export function mapApiExtraFieldToIExtraField(
  apiExtraField: IApiField,
): IExtraField {
  const levelAddressMustBeShown: boolean = apiExtraField?.type === 'caption'
    && apiExtraField?.options?.find((option) => option.key === 'show_address')
      ?.value === 'true';

  const apiExtraFieldClone = omit(apiExtraField, 'id');

  const required: boolean = apiExtraField?.options?.find((option) => option.key === 'required')
    ?.value === 'true';
  const position: string | undefined = apiExtraField?.options?.find(
    (option) => option.key === 'position',
  )?.value;
  const hiddenKey: string = apiExtraField?.options?.find((option) => option.key === 'hiddenKey')
    ?.value || '';
  let options: IOption[] | undefined;
  let selectedOption: string | undefined;
  let answer:
    | { key: string; value: any; phoneInputRequired?: boolean }[]
    | undefined;
  let placeholder: string | undefined;
  const keyQuestion: boolean | undefined = apiExtraField?.options?.find((item) => item.key === 'key_question')
    ?.value === 'true';
  const defaultPhoneCode: string | undefined = apiExtraField?.options?.find(
    (item) => item.key === 'default_phone_code',
  )?.value;
  const answerDescriptionFor: string[] = [];
  const answerTitle: string[] = [];
  const answerPlaceholder: string[] = [];
  const answerDescription: string[] = [];
  let answers: IApiAnswear[] | ICheckboxAnswer[] | undefined = _.sortBy(
    apiExtraField?.answers,
    ['id'],
  );
  const nextFieldId: number | undefined = apiExtraField?.id
    ? apiExtraField?.id
    : undefined;
  const questionDescription: string | undefined = apiExtraField?.options?.find(
    (item) => item.key === 'description',
  )?.value;
  const stepsText: string | undefined = apiExtraField?.options?.find(
    (item) => item.key === 'steps_text',
  )?.value;
  const fieldInSeries: boolean = apiExtraField?.options?.find((item) => item.key === 'field_in_series')
    ?.value === 'true';
  const uploadFilesFieldOptionsPosition: string | undefined = apiExtraField?.options?.find(
    (item) => item.key === 'upload_files_options_position',
  )?.value;
  const nameInputForNetworks: string[] = [];
  const countryCodeNotEditable: boolean = (apiExtraField?.type === 'phone'
      && apiExtraField?.options?.find(
        (item) => item.key === 'country_code_not_editable',
      )?.value === 'true')
    || ((apiExtraField?.type === 'connection_channel' // @ts-ignore
      || apiExtraField?.type === 'networks')
      && apiExtraField?.options?.find(
        (item) => item.key === 'country_code_not_editable',
      )?.value === 'true');
  const multipleFileInput: boolean = apiExtraField?.type === 'file_upload'
    && apiExtraField?.options?.find((item) => item.key === 'multiple_file_input')
      ?.value === 'true';
  const smsValidationPhone: boolean = (apiExtraField?.type === 'phone'
      && apiExtraField?.options?.find(
        (item) => item.key === 'sms_validation_phone',
      )?.value === 'true')
    || ((apiExtraField?.type === 'connection_channel' // @ts-ignore
      || apiExtraField?.type === 'networks')
      && apiExtraField?.options?.find(
        (item) => item.key === 'sms_validation_phone',
      )?.value === 'true');
  const answersWithCommentRequired: {
    option: string;
    leaveCommetRequired: string;
  }[] = [];
  let quantityMaxValue: number | string | undefined;
  let quantityMinValue: number | string | undefined;
  let quantityDefaultValue: number | string | undefined;
  let quantityButtonsBackgroundColor: string | undefined;
  let quantityButtonSignsColor: string | undefined;
  let placeholderForAdditionalField: string | undefined;
  const isAdditionalFieldShown: boolean = apiExtraField?.options?.find((item) => item.key === 'show_additional_field')
    ?.value === 'true';
  let additionalFieldAnswer: string | undefined;

  if (
    ['name', 'email', 'phone', 'extra_question'].includes(apiExtraField?.type)
  ) {
    placeholder = apiExtraField?.options?.find(
      (option) => option.key === 'placeholder',
    )?.value;
  }

  if (apiExtraField?.type === 'extra_question') {
    if (isAdditionalFieldShown) {
      placeholderForAdditionalField = apiExtraField?.options?.find(
        (option) => option.key === 'additional_field_placeholder',
      )?.value;
    }
  }
  let botUrls = {};
  if (
    [
      'checkbox',
      'radio',
      'file_upload',
      'connection_channel',
      'networks',
    ].includes(apiExtraField?.type)
  ) {
    options = apiExtraField?.options
      ?.find((option) => option.key === 'options')
      ?.value.split('|||')
      .map((option) => ({
        value: option,
        ...(apiExtraField?.type === 'checkbox' ? { selected: false } : {}),
      }));
  }

  if (
    apiExtraField?.answers
    && ['checkbox', 'radio'].includes(apiExtraField?.type)
  ) {
    answers = apiExtraField?.answers
      ?.map((item) => ({ ...item, selected: false }))
      .sort((a, b) => a.id! - b.id!);
  }

  if (
    apiExtraField?.answers
    && ['scale_star'].includes(apiExtraField?.type)
    && !parseInt(_.sortBy(apiExtraField?.answers, ['text'])[0].text!, 10)
  ) {
    answers = apiExtraField?.answers?.map((item) => ({
      ...item,
      text: `${+item.text! + 1}`,
    }));
  }

  if (['checkbox', 'radio'].includes(apiExtraField?.type)) {
    const optionsFor: { key: string; value: string }[] | undefined = apiExtraField?.options?.filter(
      (option) => option.key === 'answer_description_for',
    );

    if (optionsFor && optionsFor.length > 0) {
      optionsFor.forEach((item) => {
        const parts = item.value.split('|||');

        answerDescriptionFor.push(parts[0]);
        answerTitle.push(parts[1]);
        answerPlaceholder.push(parts[2]);
        answerDescription.push('');
        answersWithCommentRequired.push({
          option: parts[0],
          leaveCommetRequired: parts[3] || 'false',
        });
      });
    }
  }

  if (
    apiExtraField?.type === 'connection_channel' // @ts-ignore
    || apiExtraField?.type === 'networks'
  ) {
    botUrls = apiExtraField?.options;
    answer = apiExtraField?.options
      ?.find((item) => item.key === 'options')
      ?.value.split('|||')
      .map((type) => ({ key: type, value: '', name: '' }));
    const isShowNameInputForNetworksExist = apiExtraField?.options?.find(
      (item) => item.key === 'show_name_input_for',
    );
    const isShowNameInput = isShowNameInputForNetworksExist?.value?.includes('|||')
      || isShowNameInputForNetworksExist?.value === 'true';

    let answerNetworks: { key: string; value: any }[] | undefined;
    answerNetworks = answer;
    const nameInputRequiredFor = apiExtraField?.options
      ?.find((item) => item.key === 'name_input_required_for')
      ?.value?.split('|||') || [];
    const phoneInputRequiredFor = apiExtraField?.options
      ?.find((item) => item.key === 'hide_field_for')
      ?.value?.split('|||') || [];

    if (nameInputRequiredFor.length > 0) {
      answerNetworks = answerNetworks?.map((item) => (nameInputRequiredFor.includes(item.key)
        ? { ...item, nameInputRequired: true }
        : { ...item, nameInputRequired: false }));
    }

    // if (phoneInputRequiredFor.length > 0) {
    answerNetworks = answerNetworks?.map((item) => (phoneInputRequiredFor.includes(item.key)
      ? { ...item, phoneInputRequired: false }
      : { ...item, phoneInputRequired: true }));
    // }
    if (isShowNameInput) {
      const values = apiExtraField?.options
        ?.find((item) => item.key === 'options')
        ?.value.split('|||')
        ?.filter((item) => item !== 'without answer') || [];
      for (const value of values) {
        nameInputForNetworks.push(value);
      }
    }
    answer = answerNetworks;
  }

  if (apiExtraField?.type === 'file_upload') {
    answer = [];
  }

  if (apiExtraField?.type === 'quantity') {
    quantityMaxValue = apiExtraField?.options?.find(
      (item) => item.key === 'max',
    )?.value;
    quantityMaxValue = typeof quantityMaxValue === 'string'
      ? +quantityMaxValue
      : quantityMaxValue;
    quantityMinValue = apiExtraField?.options?.find(
      (item) => item.key === 'min',
    )?.value;
    quantityMinValue = typeof quantityMinValue === 'string'
      ? +quantityMinValue
      : quantityMinValue;
    quantityDefaultValue = apiExtraField?.options?.find(
      (item) => item.key === 'default_quantity_value',
    )?.value;
    quantityDefaultValue = typeof quantityDefaultValue === 'string'
      ? +quantityDefaultValue
      : quantityDefaultValue;

    const isQuantityButtonsBackgroundColorExist = apiExtraField?.options?.find(
      (item) => item.key === 'quantity_buttons_background_color',
    )?.value;
    quantityButtonsBackgroundColor = isQuantityButtonsBackgroundColorExist
      && isQuantityButtonsBackgroundColorExist.includes('|||')
      ? isQuantityButtonsBackgroundColorExist.split('|||')[1]
      : isQuantityButtonsBackgroundColorExist
          && !isQuantityButtonsBackgroundColorExist.includes('|||')
        ? isQuantityButtonsBackgroundColorExist
        : undefined;

    const isQuantityButtonSignsColorExist = apiExtraField?.options?.find(
      (item) => item.key === 'quantity_buttons_signs_color',
    )?.value;
    quantityButtonSignsColor = isQuantityButtonSignsColorExist
      && isQuantityButtonSignsColorExist.includes('|||')
      ? isQuantityButtonSignsColorExist.split('|||')[1]
      : isQuantityButtonSignsColorExist
          && !isQuantityButtonSignsColorExist.includes('|||')
        ? isQuantityButtonSignsColorExist
        : undefined;
  }

  let isMain: boolean | undefined;
  let scaleType: string | undefined;
  let scaleGradientColors: string[] | undefined;
  let scalePointerColor: string | undefined;
  let numbersColor: string | undefined;
  let selectedButtonColor: string | undefined;
  let selectedStarColor: string | undefined;
  let notSelectedStarBorder: string | undefined;
  let notSelectedStarBackground: string | undefined;
  let scaleMaxValue: number | string | undefined;
  let scaleDefaultValue: number | string | undefined;
  let signatureMin: string | undefined;
  let signatureMax: string | undefined;
  let changeBackgroundColor: boolean | undefined;
  let backgroundColors: string[] | undefined;
  let changeMascot: boolean | undefined;
  let mascots: string[] | undefined;
  let selectedNumberColor: string | undefined;
  let showDefaultValue: boolean | undefined;
  let signatureColor: string | undefined;

  if (
    apiExtraField?.type === 'nps'
    || apiExtraField?.type === 'csat'
    || apiExtraField?.type === 'ces'
    || apiExtraField?.type === 'scale'
  ) {
    isMain = apiExtraField?.options?.find((option) => option.key === 'isMain')
      ?.value === 'true' || false;
    scaleType = apiExtraField?.type.split('_')[1];
    showDefaultValue = apiExtraField?.options?.find(
      (option) => option.key === 'show_default_value',
    )?.value === 'true';

    if (scaleType === 'color') {
      scaleGradientColors = apiExtraField?.options
        ?.filter((option) => option.key.startsWith('main_scale_color'))
        .map((option) => option.value.split('|||')[1]);
      scalePointerColor = apiExtraField?.options
        ?.find((option) => option.key === 'pointer_color')
        ?.value.split('|||')[1];
      scaleDefaultValue = apiExtraField?.options?.find(
        (option) => option.key === 'default_value',
      )?.value;
      scaleDefaultValue = typeof scaleDefaultValue === 'string'
        ? +scaleDefaultValue - 1
        : scaleDefaultValue;
    }

    if (scaleType === 'numeric') {
      numbersColor = apiExtraField?.options
        ?.find((option) => option.key === 'numbers_color')
        ?.value.split('|||')[1];
      selectedButtonColor = apiExtraField?.options
        ?.find((option) => option.key === 'selected_button_color')
        ?.value.split('|||')[1];
      selectedNumberColor = apiExtraField?.options
        ?.find((option) => option.key === 'selected_number_color')
        ?.value.split('|||')[1];
      scaleDefaultValue = apiExtraField?.options?.find(
        (option) => option.key === 'default_value',
      )?.value;
      scaleDefaultValue = typeof scaleDefaultValue === 'string'
        ? +scaleDefaultValue
        : scaleDefaultValue;
    }

    if (scaleType === 'star') {
      selectedStarColor = apiExtraField?.options
        ?.find((option) => option.key === 'selected_star_color')
        ?.value.split('|||')[1];
      notSelectedStarBorder = apiExtraField?.options
        ?.find((option) => option.key === 'not_selected_star_border')
        ?.value.split('|||')[1];
      notSelectedStarBackground = apiExtraField?.options
        ?.find((option) => option.key === 'not_selected_star_background')
        ?.value.split('|||')[1];
      scaleDefaultValue = apiExtraField?.options?.find(
        (option) => option.key === 'default_value',
      )?.value;
      scaleDefaultValue = typeof scaleDefaultValue === 'string'
        ? +scaleDefaultValue
        : scaleDefaultValue;
    }

    scaleMaxValue = apiExtraField?.options?.find(
      (option) => option.key === 'max_value',
    )?.value;
    scaleMaxValue = typeof scaleMaxValue === 'string' ? +scaleMaxValue : scaleMaxValue;

    signatureMin = apiExtraField?.options?.find(
      (option) => option.key === 'signature_min',
    )?.value;
    signatureMax = apiExtraField?.options?.find(
      (option) => option.key === 'signature_max',
    )?.value;

    changeBackgroundColor = apiExtraField?.options?.find(
      (option) => option.key === 'change_background',
    )?.value === 'true';
    backgroundColors = apiExtraField?.options
      ?.filter((option) => option.key.startsWith('background_color'))
      .map((option) => option.value.split('|||')[1]);
    changeMascot = apiExtraField?.options?.find((option) => option.key === 'change_mascot')
      ?.value === 'true';
    mascots = apiExtraField?.options
      ?.filter((option) => option.key.startsWith('mascot'))
      .map((option) => option.value.split('|||')[1]);
    signatureColor = apiExtraField?.options
      ?.find((option) => option.key === 'signature_text_color')
      ?.value.split('|||')[1];
  }

  // if (smsValidationPhone) {
  //   store.dispatch(setSmsVerifyEnabled(true));
  // }

  const params = Object.fromEntries(
    new URLSearchParams(window.location.search),
  );

  return {
    id: apiExtraField?.id,
    ...apiExtraFieldClone,
    answer,
    ...(params[hiddenKey] !== undefined && { answer: params[hiddenKey] }),
    ...(position !== undefined && { position }),
    isMain: isMain || false,
    ...(keyQuestion !== undefined && { keyQuestion }),
    ...(defaultPhoneCode !== undefined && { defaultPhoneCode }),
    ...(hiddenKey !== '' && { hiddenKey }),
    ...(placeholder && { placeholder }),
    ...(answerDescriptionFor.length > 0 && { answerDescriptionFor }),
    ...(answerTitle.length > 0 && { answerTitle }),
    ...(answerPlaceholder.length > 0 && { answerPlaceholder }),
    ...(answerDescription.length > 0 && { answerDescription }),
    ...(required !== undefined && { required }),
    ...(levelAddressMustBeShown !== undefined && { levelAddressMustBeShown }),
    ...(options !== undefined && { options }),
    ...((apiExtraField?.type === 'radio'
      || apiExtraField?.type === 'connection_channel' // @ts-ignore
      || apiExtraField?.type === 'networks') && { selectedOption, botUrls }),
    ...(scaleType !== undefined && { scaleType }),
    ...(scaleGradientColors !== undefined && { scaleGradientColors }),
    ...(scalePointerColor !== undefined && { scalePointerColor }),
    ...(numbersColor !== undefined && { numbersColor }),
    ...(selectedButtonColor !== undefined && { selectedButtonColor }),
    ...(selectedStarColor !== undefined && { selectedStarColor }),
    ...(notSelectedStarBorder !== undefined && { notSelectedStarBorder }),
    ...(notSelectedStarBackground !== undefined && {
      notSelectedStarBackground,
    }),
    ...(scaleMaxValue !== undefined && {
      scaleMaxValue: scaleMaxValue as number,
    }),
    ...(scaleDefaultValue !== undefined && {
      scaleDefaultValue: scaleDefaultValue as number,
    }),
    ...(signatureMin !== undefined && { signatureMin }),
    ...(signatureMax !== undefined && { signatureMax }),
    ...(changeBackgroundColor !== undefined && { changeBackgroundColor }),
    ...(backgroundColors !== undefined && { backgroundColors }),
    ...(changeMascot !== undefined && { changeMascot }),
    ...(mascots !== undefined && { mascots }),
    ...(answers !== undefined && { answers }),
    ...(nextFieldId !== undefined && { nextFieldId }),
    ...(questionDescription !== undefined && { questionDescription }),
    ...(stepsText !== undefined && { stepsText }),
    ...(uploadFilesFieldOptionsPosition !== undefined && {
      uploadFilesFieldOptionsPosition,
    }),
    fieldInSeries,
    ...(selectedNumberColor !== undefined && { selectedNumberColor }),
    ...(showDefaultValue !== undefined && { showDefaultValue }),
    ...(nameInputForNetworks.length > 0 && { nameInputForNetworks }),
    ...(signatureColor !== undefined && { signatureColor }),
    ...((apiExtraField?.type === 'phone'
      || apiExtraField?.type === 'connection_channel' // @ts-ignore
      || apiExtraField?.type === 'networks') && {
      countryCodeNotEditable,
    }),
    ...((apiExtraField?.type === 'phone'
      || apiExtraField?.type === 'connection_channel' // @ts-ignore
      || apiExtraField?.type === 'networks') && { smsValidationPhone }),
    ...(apiExtraField?.type === 'file_upload' && { multipleFileInput }),
    ...(answersWithCommentRequired.length > 0 && {
      answersWithCommentRequired,
    }),
    ...(quantityMaxValue !== undefined && {
      quantityMaxValue: quantityMaxValue as number,
    }),
    ...(quantityMinValue !== undefined && {
      quantityMinValue: quantityMinValue as number,
    }),
    ...(quantityDefaultValue !== undefined && {
      quantityDefaultValue: quantityDefaultValue as number,
    }),
    ...(quantityButtonsBackgroundColor !== undefined && {
      quantityButtonsBackgroundColor,
    }),
    ...(quantityButtonSignsColor !== undefined && { quantityButtonSignsColor }),
    ...(placeholderForAdditionalField && { placeholderForAdditionalField }),
    ...(apiExtraField?.type === 'extra_question'
      && isAdditionalFieldShown !== undefined && { isAdditionalFieldShown }),
    ...(apiExtraField?.type === 'extra_question'
      && isAdditionalFieldShown && { additionalFieldAnswer }),
  };
}

export function getSubmitButtonText(
  isPageByPageForm: boolean,
  selectedLanguage: string,
  isRequiredField: boolean,
  isSubmit: boolean,
  submitText: string,
): string {
  if (isSubmit && submitText.length) {
    return submitText;
  }
  if (isRequiredField && isPageByPageForm) {
    return translations[selectedLanguage]?.next;
  }
  if (!isRequiredField && isPageByPageForm) {
    return translations[selectedLanguage]?.skip;
  }
  return translations[selectedLanguage]?.submit;
}
