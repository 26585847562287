/* eslint-disable react-hooks/rules-of-hooks */
import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { FormStyles, StepsTextStyles } from './FormStyles';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import {
  Form as FormikForm,
  Formik,
  FormikProps,
  useFormikContext,
  FormikErrors,
} from 'formik';
import { fieldsValidationByLang, IFormField } from './formFields';
import { IExtraField, TConnectionChannel } from 'entities/IExtraField';
import {
  SubmitButton,
  SocialNetworksModule,
  BackButton,
} from 'components/form';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import { translations } from 'i18n/translations';
import { GlobalStyles } from '../../globalStyles';
import { ModalGoogle } from 'components/modals';
import { IFormState } from './types';
import { TopSection } from 'components/form/topSection';
import {
  createButton,
  createCaption,
  createCheckboxField,
  createConfirmationField,
  createFileUploadPermissionModule,
  createHiddenField,
  createParagraph,
  createPicture,
  createQuantityField,
  createRadioField,
  createScaleField,
  createSimpleField,
  renderDateField,
  renderDropdown,
} from './fields/renderFields';
import { submitForm } from 'state/thunk/formThunkV2';
import { Header } from 'components/form/header';
import { IApiField } from 'api/entities/ApiForm';
import { useForm, useDynamicMascot, useDynamicBackground } from './useForm';
import { useFormNavigation } from 'hooks/navigation/useFormNavigation';
import { getSubmitButtonText } from 'adapters/form';
import {
  useFieldStyles,
  useFontStyles,
  useGlobalStyles,
} from 'hooks/styles/useStyles';
import { useLanguage } from 'hooks/language/useLanguage';
import { setBackgroundColor, setMoscot } from 'state/slices/formSettings';
import {
  useChangableSettings,
  useFormSettings,
  useNodeSettings,
  useSettings,
} from 'hooks/formSettings/useFormSettings';
import { goBack, goToPage } from 'state/slices/navigationSlice';
import { intersection } from 'lodash';
import { DevText } from 'components/dev';
import { useInterfaceSettings } from 'hooks/interface/useInterfaceSettings';
import {
  decreaseFont,
  increaseFont,
  setGrayMode,
} from 'state/slices/interfaceSlice';
import { proxyFile } from 'utils/proxyFile';
import { useFieldOptions } from 'hooks/options/useOptions';
import { TFieldOption } from 'state/slices/optionSlice/types';

function ScrollToError() {
  const formik = useFormikContext();
  const submitting = formik?.isSubmitting;

  useEffect(() => {
    const el = document.querySelector('.form-field-error');
    if (el) {
      el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'nearest',
      });
    }
  }, [submitting]);
  return null;
}

const currentDate = new Date().getTime();
// const maxDate = 1000 * 24 * 3600;

export default function Form() {
  const backgroundRef = React.createRef<HTMLDivElement>();
  const { search } = useLocation();
  const { slug } = useParams();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { page } = useFormNavigation();
  const { submitButtonStyles } = useAppSelector((state) => state.style);
  const { actualMascot, actualBackgroundColor } = useChangableSettings();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const {
    isTransparentBg,
    backgroundColor,
    logoLocation,
    startPageButtonPercentWidth,
    stepsTextUnderlineColor,
    stepsTextColor,
  } = useGlobalStyles();

  const { showNodeCode, nodeID } = useNodeSettings();

  const {
    id, sbmBtnText, isPageByPageForm, fraudHours,
  } = useFormSettings();

  const { defaultMascot } = useGlobalStyles();
  const styles = useFieldStyles();
  const { companyID, sessionId, googlePlaceId } = useSettings();

  const maxDate = useMemo(() => 1000 * fraudHours * 3600, [fraudHours]);

  const fraudKey = `${companyID}_${slug}`;
  const { availableLanguages: languages, language } = useLanguage();
  const selectedLanguage = language?.name ?? 'UA';

  const storedThunks = JSON.parse(localStorage.getItem(fraudKey) as string);
  const fraud: boolean = useMemo(() => {
    if (storedThunks) {
      return currentDate - storedThunks.currentDate < maxDate;
    }
    return false;
  }, [fraudKey, maxDate]);

  const {
    isLoading, statusCode, selectedPage, currentFormFields, isSubmit,
  } = useForm(
    selectedLanguage,
    fraudKey,
    storedThunks && currentDate - storedThunks.currentDate < maxDate,
  );
  // const { smsVerifyEnabled, phone: verifiedPhone } = useOptions();
  const { countryCode } = useAppSelector((state) => state.form.formSettings);

  const stepText = statusCode === 200
    ? selectedPage?.options?.find((option) => option.key === 'steps_text')
      ?.value ?? ''
    : '';

  const [errorMessage, setErrorMessage] = useState<any>({});
  const [submitError, setSubmitError] = useState<string>('');
  const [mainScaleSettings, setMainScaleSettings] = useState<IExtraField>();
  const [mainScaleAnswer, setMainScaleAnswer] = useState<number>();
  const [selectedNetwork, setSelectedNetwork] = useState<number>();
  const [sendButton, setSendButton] = useState<any>({
    type: 'send_button',
    text: '',
    backgroundColor: '#0E9285',
    disabledBackgroundColor: '#CCCCCC',
    textColor: 'var(--root-white)',
    disabledTextColor: 'var(--root-white)',
  });
  const [nextFieldId, setNextFieldId] = useState<number | null>();
  // const [isBackButtonShown, setIsBackButtonShown] = useState<boolean>(true);
  // const [isButtonAlreadyClicked, setIsButtonAlreadyClicked] = useState<boolean>(false);
  const [formTitleDescription, setFormTitleDescription] = useState<string>();
  const [botsURLs, setBotsURLs] = useState<{
    telegramURL: string;
    viberURL: string;
    whatsappURL: string;
  } | null>();
  const [chosenNetwork, setChosenNetwork] = useState<string>();
  const [dividingLineStyles, setDividingLineStyles] = useState<{ lineWidth: string; lineColor: string; lineHeight: string }>();
  const [sourceId, setSourceId] = useState<number | null>();
  const [npsValue, setNpsValue] = useState<number>(0);
  const [starValue, setStarValue] = useState<number>(0);
  const [isGoogleModal, setGoogleModal] = useState<boolean>(false);
  const networksRef = useRef<string[]>([]);
  const formRef = useRef<FormikProps<IFormState>>(null);

  const showGoogleModal = () => setGoogleModal(true);

  // const index = useMemo(
  //   () => fields.findIndex((item) => item.id === currentFieldId),
  //   [currentFieldId, fields],
  // );

  // const botUrl = useMemo(() => {
  //   if (telegramOrViberNetwork && botsURLs) {
  //     return chosenNetwork === 'telegram'
  //       ? botsURLs?.telegramURL
  //       : chosenNetwork === 'viber'
  //         ? botsURLs?.viberURL
  //         : botsURLs?.whatsappURL;
  //   }
  //   return '';
  // }, [chosenNetwork, botsURLs]);

  // const startPageButtonWidth = formStyles?.find(
  //   (item) => item.key === 'start_page_button_percent_width',
  // )?.value;

  const { pagesHistory } = useAppSelector((state) => state.navigation);

  const isBackButtonShown = pagesHistory.length > 1;

  const extraSubmitButtonStyles = isBackButtonShown
    ? { marginTop: '0px', width: '100%' }
    : { width: `${startPageButtonPercentWidth || '100'}%`, marginTop: '30px' };

  const backButtonText = sourceId === 3
    ? translations[selectedLanguage!]?.refresh
    : translations[selectedLanguage!]?.back;

  const fieldsValidation = useMemo(
    () => fieldsValidationByLang(countryCode, languages?.[0]?.name),
    [countryCode, languages, currentFormFields],
  );

  const validationSchema = useMemo(() => {
    if (Object.keys(currentFormFields).length) {
      const schema: { [key: string]: any } = {};
      Object.keys(currentFormFields).forEach((key: string) => {
        const obj: IFormField = currentFormFields[key] as IFormField;
        // @ts-ignore
        schema[key] = (obj && fieldsValidation[obj?.type]) || Yup.object();
      });
      return Yup.object({
        extraFields: Yup.object(schema),
        // @ts-ignores
        //   documents: Yup.array().of(
        //       Yup.lazy(
        //         // @ts-ignore
        //         (obj: IDocument | undefined) => (obj && fieldsValidation[obj?.type]) || Yup.object(),
        //       ) as any,
        //   ),
      });
    }
  }, [selectedLanguage, currentFormFields]);

  // const solutionNotReqStatus = useMemo(
  //   () => (companyId === 111
  //       && npsValue! > 8
  //       && chosenNetwork === 'without answer')
  //     || sourceId === 3,
  //   [companyId, npsValue, chosenNetwork, sourceId],
  // );

  function getErrorMessage(key: string) {
    return typeof errorMessage === 'object'
      ? errorMessage[key]
          && `* ${errorMessage[key].charAt(0).toUpperCase()}${errorMessage[
            key
          ].substring(1)}`
      : undefined;
  }

  function setNextField(field: IExtraField | undefined, value: any) {
    // const answersNewArray = field?.answers && [...field.answers];
    // const answers = answersNewArray?.sort((a, b) => +a.text! - +b.text!);
    // const choosenValue = field?.type === 'scale_color' ? value[0] : value;
    // const current = answers?.find((item) => +item.text! === choosenValue);
    // const nextStepId = current?.nextField
    //   ? current.nextField
    //   : field?.nextFieldId;
    // setNextFieldId(nextStepId);
  }

  function handleIsOneOfFieldRequired(arr: any) {
    for (let i = 0; i < arr.length; i += 1) {
      const isFieldRequired = arr[i].required;
      if (isFieldRequired) {
        return true;
      }
    }
    return false;
  }

  function handleKeyUp(key: string) {
    setErrorMessage(
      typeof errorMessage === 'object'
        ? { ...errorMessage, [key]: undefined }
        : errorMessage,
    );
  }

  const handleRatingChange = (
    value: number | number[],
    field: IExtraField,
  ): void => {
    const selectedFieldIndex = field.id;
    const selectedStyles = styles.get(field.id!);

    formRef.current?.setFieldValue(
      `extraFields[${selectedFieldIndex}str].answer`,
      typeof value === 'number' ? `${value}` : `${value[0]}`,
    );

    if (mainScaleSettings && field?.id === mainScaleSettings.id) {
      setMainScaleAnswer(typeof value === 'number' ? value : value[0]);
    }

    if (field?.type === 'nps') {
      if (selectedStyles?.changeBackground) {
        dispatch(
          setBackgroundColor({
            color:
              selectedStyles!.backgroundColor[
                typeof value === 'number' ? value : value[0]
              ],
          }),
        );
      }
      if (selectedStyles?.changeMascot) {
        dispatch(
          setMoscot({
            url: selectedStyles!.mascot[
              typeof value === 'number' ? value + 1 : value[0] + 1
            ],
          }),
        );
      }
    }
    if (field?.type === 'nps' && companyID === 111) {
      setNpsValue(value as number);
    }
    if (field?.type === 'nps' && slug === 'rozsilka-1d597066') {
      if (field?.isMain && (value as number) > 8) {
        showGoogleModal();
        // setGoogleModal(true);
      }
    }
    // @ts-ignore
    if (field?.type === 'scale_star') {
      if (selectedStyles?.changeBackground) {
        dispatch(
          setBackgroundColor({
            color:
              selectedStyles!.backgroundColor[
                typeof value === 'number' ? value : value[0]
              ],
          }),
        );
      }
      if (selectedStyles?.changeMascot) {
        dispatch(
          setMoscot({
            url: selectedStyles!.mascot[
              typeof value === 'number' ? value : value[0]
            ],
          }),
        );
      }
      // if (typeof value === 'number' ? value > 4 : value[0] > 4) {

      // }
      if (field?.isMain) {
        setStarValue(value as number);

        if ((value as number) >= 4 && companyID !== 164) {
          showGoogleModal();
          // setGoogleModal(true);
        }
      }
    }
    setNextField(field, value);
  };

  function renderField(
    field: IApiField,
    setFieldValue: (key: string, value: any) => void,
    index: number,
    values: { extraFields: { [key: string]: IExtraField } },
    errors: any,
    handleChange: ((e: React.ChangeEvent<any>) => void) | undefined,
  ) {
    const selectedFieldIndex = field.id as any;
    const selectedField = values.extraFields[field.id];
    if (
      !selectedField
      && !['caption', 'paragraph', 'picture'].includes(field?.type)
    ) {
      return;
    }
    function createSocialNetworksModule(field: IExtraField) {
      function handleClick(index: number) {
        setSelectedNetwork(selectedNetwork === index ? undefined : index);
      }
      return (
        <SocialNetworksModule
          id_v2={field?.id ?? ''}
          // @ts-ignore
          answerPositionV2={field?.answerPosition}
          defaultPhoneCode={field?.defaultPhoneCode}
          botsUrls={botsURLs}
          description={field?.questionDescription}
          name={`extraFields[${selectedFieldIndex}str].selectedOption`}
          index={selectedFieldIndex}
          values={field}
          error={
            typeof errorMessage === 'object'
              ? getErrorMessage(
                `extraFields[${selectedFieldIndex}str].selectedOption`,
              )
              : undefined
          }
          setFieldValue={setFieldValue}
          required={field?.required || false}
          handleClick={handleClick}
          selectedNetwork={
            selectedNetwork !== undefined
              ? (networksRef.current[selectedNetwork] as TConnectionChannel)
              : undefined
          }
          question={field?.question}
          hideError
          setChosenNetwork={setChosenNetwork}
        />
      );
    }
    switch (field?.type) {
      case 'paragraph': // @ts-ignore
        return createParagraph(field as IExtraField);
      case 'confirmation': // @ts-ignore
        return createConfirmationField(
          // @ts-ignore
          field as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleChange,
        );
      case 'button':
        // @ts-ignore
        return createButton(field as IExtraField);
      case 'caption':
        // @ts-ignore
        return createCaption(field as IExtraField);
      case 'picture': // @ts-ignore
        return createPicture(field as IExtraField, actualMascot);
      case 'name':
        return createSimpleField(
          selectedField as IExtraField,
          'text',
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
          values,
        );
      case 'date':
        return renderDateField(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
        );
      case 'dropdown':
        return renderDropdown(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
        );
      case 'email':
        return createSimpleField(
          selectedField as IExtraField,
          'text',
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
          values,
        );
      case 'phone':
        return createSimpleField(
          selectedField as IExtraField,
          'phoneNumber',
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
          values,
        );
      case 'extra_question':
        return createSimpleField(
          selectedField as IExtraField,
          'textarea',
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
          values,
        );
      case 'detailed_response':
        return createSimpleField(
          selectedField as IExtraField,
          'textarea',
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
          values,
        );
      case 'checkbox':
        return createCheckboxField(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleChange,
          errorMessage,
          getErrorMessage,
          values,
        );
      case 'radio':
        return createRadioField(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          setNextFieldId,
          errorMessage,
          getErrorMessage,
          values,
        );

      // case 'scale_number':
      // case 'scale_star':
      // case 'scale':
      case 'csat':
      case 'ces':
      case 'nps':
        return createScaleField(
          selectedField as IExtraField,
          selectedFieldIndex,
          handleRatingChange,
        );
      case 'scale':
        return createScaleField(
          selectedField as IExtraField,
          selectedFieldIndex,
          handleRatingChange,
        );
      case 'file_upload':
        return createFileUploadPermissionModule(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          errorMessage,
          getErrorMessage,
          values,
        );
      // @ts-ignore
      case 'networks':
      case 'connection_channel':
        return createSocialNetworksModule(selectedField as IExtraField);
      case 'quantity':
        return createQuantityField(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
          handleKeyUp,
          errorMessage,
          getErrorMessage,
        );
      case 'hidden':
        return createHiddenField(
          selectedField as IExtraField,
          selectedFieldIndex,
          selectedField,
          setFieldValue,
        );
    }
  }

  const goToNextPage = (transitTo?: number) => {
    if (selectedPage) {
      const nextPage = transitTo ?? selectedPage?.nextPageID!;
      dispatch(
        goToPage({
          nextPageId: nextPage,
        }),
      );
    }
  };

  const validateForm = async (): Promise<
    FormikErrors<IFormState> | undefined
  > => {
    const errors = await formRef.current?.validateForm();
    return errors;
  };

  const nextPage = async () => {
    const values = formRef.current?.values;
    const touched = formRef.current?.touched;
    const errors = await validateForm();
    const touchedFields = Object.keys(touched?.extraFields ?? {});
    const current_fields = selectedPage!.fields
      ?.filter((field) => field.options?.find(
        (opt) => opt.key === 'required' && opt.value === 'true',
      ))
      .map((field) => field.id.toString());
    const current_field_ids = current_fields;
    const isValid = !intersection(
      current_field_ids,
      errors?.extraFields ? Object.keys(errors?.extraFields) : [],
    ).length;
    if (isValid) {
      const requiredField = Object.values(values?.extraFields ?? {})?.find(
        (field) => field?.pageID === page
          && field.options.find(
            (option) => option.key === 'required' && option.value === 'true',
          ),
      );
      const isScale = requiredField?.type === 'nps' || requiredField?.type === 'scale' || requiredField?.type === 'csat';
      const isScaleTouched = isScale && touchedFields.includes(`${requiredField.id}str`);
      if (isScale && !isScaleTouched) {
        current_field_ids.forEach((e) => {
          formRef.current?.setFieldError(
            `extraFields[${e}].answer`,
            `* ${translations.UA.validation_required}`,
          );
        });
        return;
      }
      const selectedAnswer = requiredField?.answer ?? requiredField?.selectedOption;
      const transitTo = requiredField?.answers.find(
        (answer) => answer.text === selectedAnswer,
      )?.transitTo;
      formRef.current?.setErrors({});
      // formRef.current?.setTouched({}, false);
      goToNextPage(transitTo);
    }
  };

  async function submit() {
    let isValid;
    const errors = await validateForm();
    const values = formRef.current?.values!;
    const fieldIds = Object.values(values?.extraFields ?? {})
      .map((e, index) => (pagesHistory.includes(e.pageID) ? e.id : undefined))
      .filter((e) => e !== undefined);

    for (const item of fieldIds) {
      if (errors?.extraFields?.[`${item!}str`]) {
        isValid = false;
        break;
      } else {
        isValid = true;
      }
    }
    if (isValid) {
      dispatch(
        submitForm({
          formState: values,
          sessionID: sessionId,
          nextPageId: selectedPage!.nextPageID,
        }),
      );
    }
  }

  useEffect(() => {
    if (formRef.current?.values.extraFields) {
      const networkField: string | undefined = Object.keys(formRef.current?.values.extraFields!).find(
        (key: string) => {
          const item = formRef.current?.values.extraFields[key]!;
          return (
          // @ts-ignore
            item?.type === 'connection_channel' || item?.type === 'networks'
          );
        },
      ) || undefined;
      if (networkField) {
        networksRef.current = formRef.current?.values.extraFields[
          networkField
        ]?.options?.map((item: any) => item.value)!;
      }
    }
  }, [formRef.current?.values.extraFields]);

  const possibleTransit = selectedPage?.fields?.some((field) => (field.answers ? field.answers?.some((e) => e.transitTo) : false));

  const isThankYouPage = selectedPage?.fields && !(selectedPage?.nextPageID || possibleTransit);

  const backButtonHandler = () => {
    dispatch(goBack());
  };

  useEffect(() => {
    if (isThankYouPage && !fraud) {
      localStorage.setItem(
        fraudKey,
        JSON.stringify({ currentDate, thanksPage: selectedPage }),
      );
      submit();
    }
  }, [isThankYouPage]);

  const renderForm = ({
    values,
    errors,
    setFieldValue,
    handleChange,
    validateField,
  }: FormikProps<IFormState>) => {
    const showHeaderAdditionalCondition = (languages && languages?.length > 1) || showNodeCode;
    const isHeaderShown = isPageByPageForm || (!isPageByPageForm && showHeaderAdditionalCondition);
    const selectedMascot = useDynamicMascot(values.extraFields);
    const selectedBackground = useDynamicBackground(values.extraFields);
    if (selectedBackground && backgroundRef.current?.style) {
      backgroundRef.current.style.backgroundColor = selectedBackground;
    }
    const setFieldVal = async (
      field: string,
      value: any,
      shouldValidate?: boolean,
    ) => {
      setFieldValue(field, value, true);
      const fieldErrors = await formRef.current?.validateField(field);
    };
    return (
      <FormikForm id="mainForm">
        <div className="body">
          {true && <Header />}
          <div className="mainContentContainer">
            {/* <TopSection
              isCaptionPage={selectedPage?.fields?.some(
                (e) => e.type === 'caption',
              )}
            /> */}
            {/* {defaultMascot && (
              <img
                src={proxyFile(selectedMascot ?? defaultMascot)}
                alt="Логотип"
              />
            )} */}
            <div className="extraFieldListContainer">
              <div className="formExtraFields">
                <div className={`extraFieldsList page-${selectedPage?.id}`}>
                  {selectedPage?.fields?.map((field, index) => (
                    <div
                      key={index}
                      className="extraFieldContainer"
                      style={field.type === 'radio' ? { marginBottom: 10 } : {}}
                    >
                      <div className="fields">
                        {renderField(
                          field,
                          setFieldVal,
                          index,
                          values,
                          errors,
                          handleChange,
                        )}
                        {field.type === 'caption' && defaultMascot && !isPageByPageForm && (
                          <img
                            style={{ alignSelf: 'center' }}
                            src={proxyFile(selectedMascot ?? defaultMascot)}
                            alt="Логотип"
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {!isThankYouPage && (
                <div
                  className={`buttonsContainer ${
                    isBackButtonShown && 'buttonsContainerFlex'
                  }`}
                >
                  {isPageByPageForm && isBackButtonShown && (
                    <BackButton
                      isPageByPageForm={isPageByPageForm}
                      handleBackButtonClick={backButtonHandler}
                    >
                      {backButtonText ?? 'Назад'}
                    </BackButton>
                  )}
                  <SubmitButton
                    type="button"
                    onClick={nextPage}
                    disabled={isLoading}
                    selectedLanguage={selectedLanguage}
                    isPageByPageForm={isPageByPageForm}
                    isLoading={isLoading}
                    selectedPage={selectedPage}
                    textColor={
                      submitButtonStyles?.default?.color?.[1]
                      ?? 'var(--root-white)'
                    }
                    extraButtonStyles={extraSubmitButtonStyles}
                    bgColor={
                      submitButtonStyles?.default?.backgroundColor?.[1]
                      ?? 'var(--root-black)'
                    }
                  >
                    {getSubmitButtonText(
                      isPageByPageForm,
                      selectedLanguage,
                      true,
                      isSubmit,
                      sbmBtnText,
                    )}
                  </SubmitButton>
                  <DevText>
                    Наступна сторінка: {selectedPage?.nextPageID}
                  </DevText>
                </div>
              )}
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  width: '100%',
                }}
              >
                {isPageByPageForm && stepText && (
                  <StepsTextStyles
                    companyId={companyID!}
                    fontFamily={fontFamily}
                    isFontWeightBold={isFontWeightBold}
                    bottomLineColor={stepsTextUnderlineColor ?? ''}
                    textColor={stepsTextColor ?? 'var(--root-black)'}
                  >
                    <div className="stepsTextContainer">
                      <p className="stepsText">{stepText}</p>
                    </div>
                  </StepsTextStyles>
                )}
                {isPageByPageForm && (
                  <div className="uploadErrorContainer">
                    <p className="uploadError">{submitError}</p>
                  </div>
                )}
              </div>
            </div>
            <div />
          </div>
          <div />
          <ScrollToError />
        </div>
      </FormikForm>
    );
  };

  const {
    grayMode, fontScale, fullWidth, activeTab,
  } = useInterfaceSettings();

  const changeContrast = () => {
    dispatch(setGrayMode(!grayMode));
  };

  const [isAccessebilityMenuOpened, setIsAccessebilityMenuOpened] = useState(false);

  return (
    <GlobalStyles fontFamily={fontFamily} fontUrl={fontUrl}>
      {statusCode === 200 && (
        <FormStyles
          isOpened={isAccessebilityMenuOpened}
          grayMode={grayMode}
          fontScale={fontScale}
          companyId={companyID!}
          fontFamily={fontFamily}
          isTransparentBg={isTransparentBg}
          isFontWeightBold={isFontWeightBold}
        >
          <DevText>поточна сторінка: {selectedPage?.id}</DevText>
          <div
            ref={backgroundRef}
            className="formContainer"
            style={{
              backgroundColor: backgroundColor![1],
              transition: 'background-color 0.5s linear',
            }}
          >
            {statusCode === 200
              && !isLoading
              && !!Object.keys(currentFormFields).length && (
                <Formik
                  enableReinitialize
                  innerRef={formRef}
                  initialValues={{
                    extraFields: currentFormFields,
                  }}
                  onSubmit={submit}
                  validationSchema={validationSchema}
                  // validateOnChange
                  validateOnChange={false}
                  validateOnBlur={false}
                  validateOnMount={false}
                >
                  {renderForm}
                </Formik>
            )}
            {isLoading && <p style={{ textAlign: 'center' }}>Loading...</p>}
          </div>
        </FormStyles>
      )}
      {googlePlaceId && isGoogleModal && companyID && id && sessionId && (
        <ModalGoogle
          sessionId={sessionId!}
          placeId={googlePlaceId!}
          nodeId={nodeID!}
          companyId={companyID ?? 0}
          formId={id!}
          onClose={() => setGoogleModal(false)}
        />
      )}
      {/* {phoneConfirmation && (
        <ModalPhoneConfirmation
          buttonStyle={sendButton}
          setLoading={setLoading}
          setSuccess={setSuccess}
          setPhoneConfirmation={setPhoneConfirmation}
          companyID={companyId}
          botUrl={botUrl}
        />
      )} */}
    </GlobalStyles>
  );
}
