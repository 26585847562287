/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useEffect, useState, useCallback } from 'react';
import { ErrorMessage, useFormikContext } from 'formik';
import { debounce } from 'lodash';
import { QuantityFieldStyles } from './QuantityFieldStyles';
import { FormErrorMessage } from 'components/formErrorMessage';
import { QuantityMinusIcon, QuantityPlusIcon } from 'assets/icons';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { IQuantityFieldProps } from './types';
import { useFieldStyles, useFontStyles } from 'hooks/styles/useStyles';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { IFormState } from 'pages/form/types';
import { DevText } from 'components/dev';

export function QuantityField({
  id,
  index,
  answerPositionV2,
  name,
  value,
  // error,
  question,
  required,
  description,
  // isPageByPageForm,
  minValue,
  maxValue,
  defaultValue,
  buttonsBackgroundColor,
  signsColor,
  onChange,
  onKeyUp,
  hideError = false,
}: IQuantityFieldProps) {
  const { touched, errors, handleBlur } = useFormikContext<IFormState>();
  const { isFontWeightBold, fontFamily, fontUrl } = useFontStyles();
  const { isPageByPageForm } = useFormSettings();
  const valueAsNumber = Number(value);
  const [quantity, setQuantity] = useState<number>(valueAsNumber);

  const increaseQuantity = () => {
    if (value === '') {
      onChange(name, `${minValue}`);
    }
    if (quantity < maxValue!) {
      setQuantity(quantity + 1);
    }
  };

  const decreaseQuantity = () => {
    if (value === '') {
      onChange(name, `${minValue}`);
    }
    if (quantity > minValue!) {
      setQuantity(quantity - 1);
    }
  };

  const onChangeHandler = (event: any) => {
    setQuantity(Number(event.target.value));
  };

  const debouncedChangeHandler = useCallback(debounce(onChangeHandler, 50), []);

  useEffect(() => {
    if (onChange && defaultValue !== undefined) {
      onChange(name, `${defaultValue}`);
      setQuantity(defaultValue);
    }
  }, [id, defaultValue]);

  useEffect(() => {
    if (value !== '') {
      onChange(name, `${quantity}`);
    }
  }, [quantity]);

  const error = errors.extraFields?.[`${id}str`]?.answer;

  return (
    <QuantityFieldStyles
      fontFamily={fontFamily}
      buttonsBackgroundColor={buttonsBackgroundColor}
    >
      <DevText>{id}</DevText>
      {question && (
        <div className="titleContainer" role="region">
          <h1
            /* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */
            tabIndex={0}
            id="question"
            aria-label={questionTextByFormType(
              isPageByPageForm,
              answerPositionV2,
              question,
            )}
          >
            {questionTextByFormType(
              isPageByPageForm,
              answerPositionV2,
              question,
            )}
          </h1>
        </div>
      )}

      {description && (
        <div className="titleContainer" role="region">
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
          <h1 tabIndex={0} id="question" aria-label={description}>
            {description}
          </h1>
        </div>
      )}
      <div className="quantityCounterContainer">
        <div className="quantityCounterWrapper">
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            aria-label="Зменшити кількість на 1"
            className="quantityCounterButton quantityMinusButton"
            type="button"
            onClick={decreaseQuantity}
          >
            <QuantityMinusIcon color={signsColor} />
          </button>
          <input
            aria-label="Редагувати кількість"
            className="quantityInput"
            name="quantityName"
            type="number"
            value={value}
            onChange={
              onChange
                ? (event) => {
                  onChange(name, event.target.value);
                  debouncedChangeHandler(event);
                }
                : () => null
            }
            onBlur={handleBlur}
            onKeyUp={onKeyUp}
          />
          {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
          <button
            aria-label="Збільшити кількість на 1"
            className="quantityCounterButton quantityPlusButton"
            type="button"
            onClick={increaseQuantity}
          >
            <QuantityPlusIcon color={signsColor} />
          </button>
        </div>
        {hideError && (
          <div className="formErrorContainer">
            {error && (
              <p
                aria-live="polite"
                tabIndex={0}
                aria-label={`Помилка ${error}`}
              >
                {error}
              </p>
            )}
            {/* {touched && (
              <ErrorMessage name={name} component={FormErrorMessage} />
            )} */}
          </div>
        )}
      </div>
    </QuantityFieldStyles>
  );
}
