import { FC } from 'react';
import { TLinkProps } from './types';
import { SubmitButton } from '../submitButton/SubmitButton';
import { useAppSelector } from 'state/hooks';

export const LinkButtonField: FC<TLinkProps> = ({ text, link, isBlank }) => {
  const submitButtonStyles = useAppSelector(
    (state) => state.style.submitButtonStyles,
  );

  return (
    <SubmitButton
      type="button"
      // eslint-disable-next-line no-restricted-globals
      onClick={() => open(link, isBlank ? '_blank' : '')}
      textColor={submitButtonStyles.default?.color![1]}
      // extraButtonStyles={extraSubmitButtonStyles}
      bgColor={submitButtonStyles.default.backgroundColor![1]}
    >
      {text}
    </SubmitButton>
  );
};
