import styled from 'styled-components';

export const RadioFieldStyles = styled.footer<{
  fontFamily: string | undefined;
}>`
  margin-bottom: 10px;
  max-width: 540px;
  width: 100%;

  .body {
    padding: 24px 16px 0 16px;
    box-sizing: border-box;

    .inputContainer {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr;
    }

    .inputRaw {
      display: grid;
      grid-template-columns: 1fr auto;
      //grid-gap: 16px;

      input {
        height: 40px;
        font-size: 14px;
        color: var(--root-black);
        background-color: var(--root-white);
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        border-radius: 4px;
        padding: 0 16px;

        &::placeholder {
          font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};
          font-size: 14px;
          color: #979797;
        }

        &.disabled {
          background-color: #fafafa;
        }
      }

      .infoContainer {
        width: 34px;
        height: 40px;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;

        &:hover .infoText {
          display: block;
        }

        .infoIcon {
          width: 16px;
          height: 16px;
          border-radius: 12px;
          border: 1px solid #0e9285;
          display: grid;
          place-items: center;

          svg {
            width: 12px;
            height: 12px;
            filter: invert(42%) sepia(68%) saturate(490%) hue-rotate(125deg)
              brightness(96%) contrast(99%);
          }
        }

        .infoText {
          display: none;
          width: 280px;
          padding: 16px;
          position: absolute;
          right: -8px;
          top: 38px;
          font-size: 14px;
          color: var(--root-black);
          background-color: var(--root-white);
          border-radius: 4px;
          box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.15);
        }
      }
    }
  }

  .addFieldButtonContainer {
    input {
      height: 32px;
      font-size: 14px;
      color: var(--root-black);
      background-color: var(--root-white);
      border: 1px solid #e5e5e5;
      box-sizing: border-box;
      border-radius: 4px;
      padding: 0 16px;

      &::placeholder {
        font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};
        font-size: 14px;
        color: #979797;
      }

      &.disabled {
        background-color: #fafafa;
      }
    }
  }

  .options {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 8px;
    row-gap: 12px;
  }

  .answerTitleContainer {
    margin-bottom: 4px;

    p {
      margin: 0;
      font-size: 13px;
      color: #222;
    }
  }

  .radioFieldContainer {
    .choiceDescription {
      margin-top: 8px;
    }
  }

  .fieldName {
    font-weight: 500;
    font-size: 15px;
    line-height: 16px;
    color: var(--root-black);
    margin-top: 0;
    margin-bottom: 8px;
  }

  .formErrorContainer {
    height: 24px;
    font-size: 11px;
    line-height: 16px;
    color: #D92A2A;
  }
`;
