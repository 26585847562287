import { IApiPage } from 'api/entities/ApiForm';
import { extractFields } from 'pages/form/utils';
import { IFieldStyle } from 'state/slices/stylesSlice/types';
import { mapOptiosToDict } from 'utils/mapOptionsToDictionary';

export const mapOptionsToStyles = (
  type: string,
  options: { key: string; value: string }[],
) => {
  const optionsDict = mapOptiosToDict(options);
  switch (type) {
    case 'scale_color':
      return {
        required: optionsDict.get('required')?.split('|||')[0],
        mainScaleColor1: optionsDict.get('main_scale_color_1')?.split('|||')[1],
        mainScaleColor2: optionsDict.get('main_scale_color_2')?.split('|||')[1],
        mainScaleColor3: optionsDict.get('main_scale_color_3')?.split('|||')[1],
        mainScaleColor4: optionsDict.get('main_scale_color_4')?.split('|||')[1],
        pointerColor: optionsDict.get('pointer_color')?.split('|||')[1],
        signatureTextColor: optionsDict
          .get('signature_text_color')
          ?.split('|||')[0],
        maxValue: optionsDict.get('max_value')?.split('|||')[0],
        defaultValue: optionsDict.get('default_value')?.split('|||')[0],
        descriptionTextColor: optionsDict
          .get('description_teext_color')
          ?.split('|||')[1],
        signatureMin: optionsDict.get('signature_min')?.split('|||')[0],
        signatureMax: optionsDict.get('signature_max')?.split('|||')[0],
      };
    case 'scale_star':
      return {
        changeMascot: optionsDict.get('change_mascot') === 'true',
        changeBackground: optionsDict.get('change_background') === 'true',
        selectedStarColor: optionsDict
          .get('selected_star_color')
          ?.split('|||')[0],
        notSelectedStarBorder: optionsDict
          .get('not_selected_star_border')
          ?.split('|||')[0],
        notSelectedStarBackground: optionsDict
          .get('not_selected_star_background')
          ?.split('|||')[0],
        signatureTextColor: optionsDict
          .get('signature_text_color')
          ?.split('|||')[0],
        backgroundColor: {
          1: optionsDict.get('background_color_1')?.split('|||')[0],
          2: optionsDict.get('background_color_2')?.split('|||')[0],
          3: optionsDict.get('background_color_3')?.split('|||')[0],
          4: optionsDict.get('background_color_4')?.split('|||')[0],
          5: optionsDict.get('background_color_5')?.split('|||')[0],
        },
        mascot: {
          1: optionsDict.get('mascot_1')?.split('|||')[1],
          2: optionsDict.get('mascot_2')?.split('|||')[1],
          3: optionsDict.get('mascot_3')?.split('|||')[1],
          4: optionsDict.get('mascot_4')?.split('|||')[1],
          5: optionsDict.get('mascot_5')?.split('|||')[1],
        },
      };
    case 'nps':
      return {
        pointerColor: optionsDict.get('pointer_color')?.split('|||')?.[0],
        signatureTextColor: optionsDict
          .get('signature_text_color')
          ?.split('|||')?.[1],
        mainScaleColor1: optionsDict
          .get('main_scale_color_1')
          ?.split('|||')?.[0],
        mainScaleColor2: optionsDict
          .get('main_scale_color_2')
          ?.split('|||')?.[0],
        changeMascot: optionsDict.get('change_mascot') === 'true',
        changeBackground: optionsDict.get('change_background') === 'true',
        signatureMin: optionsDict.get('signature_min')?.split('|||')[0],
        signatureMax: optionsDict.get('signature_max')?.split('|||')[0],
        mascot: {
          1: optionsDict.get('mascot_1')?.split('|||')[1],
          2: optionsDict.get('mascot_2')?.split('|||')[1],
          3: optionsDict.get('mascot_3')?.split('|||')[1],
          4: optionsDict.get('mascot_4')?.split('|||')[1],
          5: optionsDict.get('mascot_5')?.split('|||')[1],
          6: optionsDict.get('mascot_6')?.split('|||')[1],
          7: optionsDict.get('mascot_7')?.split('|||')[1],
          8: optionsDict.get('mascot_8')?.split('|||')[1],
          9: optionsDict.get('mascot_9')?.split('|||')[1],
          10: optionsDict.get('mascot_10')?.split('|||')[1],
          11: optionsDict.get('mascot_11')?.split('|||')[1],
        },
      };
    case 'scale':
      return {
        pointerColor: optionsDict.get('pointer_color')?.split('|||')?.[0],
        signatureTextColor: optionsDict
          .get('signature_text_color')
          ?.split('|||')?.[1],
        mainScaleColor1: optionsDict
          .get('main_scale_color_1')
          ?.split('|||')?.[0],
        mainScaleColor2: optionsDict
          .get('main_scale_color_2')
          ?.split('|||')?.[0],
        changeMascot: optionsDict.get('change_mascot') === 'true',
        changeBackground: optionsDict.get('change_background') === 'true',
        signatureMin: optionsDict.get('signature_min')?.split('|||')[0],
        signatureMax: optionsDict.get('signature_max')?.split('|||')[0],
        mascot: {
          1: optionsDict.get('mascot_1')?.split('|||')[1],
          2: optionsDict.get('mascot_2')?.split('|||')[1],
          3: optionsDict.get('mascot_3')?.split('|||')[1],
          4: optionsDict.get('mascot_4')?.split('|||')[1],
          5: optionsDict.get('mascot_5')?.split('|||')[1],
          6: optionsDict.get('mascot_6')?.split('|||')[1],
          7: optionsDict.get('mascot_7')?.split('|||')[1],
          8: optionsDict.get('mascot_8')?.split('|||')[1],
          9: optionsDict.get('mascot_9')?.split('|||')[1],
          10: optionsDict.get('mascot_10')?.split('|||')[1],
          11: optionsDict.get('mascot_11')?.split('|||')[1],
        },
        backgroundColor: {
          1: optionsDict.get('background_color_1')?.split('|||')[1],
          2: optionsDict.get('background_color_2')?.split('|||')[1],
          3: optionsDict.get('background_color_3')?.split('|||')[1],
          4: optionsDict.get('background_color_4')?.split('|||')[1],
          5: optionsDict.get('background_color_5')?.split('|||')[1],
          6: optionsDict.get('background_color_6')?.split('|||')[1],
          7: optionsDict.get('background_color_7')?.split('|||')[1],
          8: optionsDict.get('background_color_8')?.split('|||')[1],
          9: optionsDict.get('background_color_9')?.split('|||')[1],
          10: optionsDict.get('background_color_10')?.split('|||')[1],
          11: optionsDict.get('background_color_11')?.split('|||')[1],
        },
      };
  }
};

export const fieldStylesAdapter = (
  pages: IApiPage[],
): Map<number, { [key: string]: string }> => {
  const styles = new Map<number, { [key: string]: string }>();
  const fields = extractFields(pages, true);

  fields.forEach((field) => {
    if (field?.options) {
      styles.set(
        field.id,
        // @ts-ignore
        mapOptionsToStyles(field.type, field?.styles ?? []),
      );
    }
  });
  return styles;
};
