import { StylesStateV2 } from './types';

export const initialState: StylesStateV2 = {
  globalStyles: {
    dividingLine: undefined,
    backgroundColor: undefined,
    logoLocation: undefined,
    logoSelected: undefined,
    startPageButtonPercentWidth: undefined,
    isTransparentBg: false,
    notCheckedElementsColor: undefined,
    elementsColor: undefined,
    radioButtonBorderWidth: undefined,
    radioButtonCheckedBorderRadius: undefined,
    radioButtonCheckedSizes: undefined,
    notRadioCheckedElementsColor: undefined,
    stepsTextUnderlineColor: undefined,
    stepsTextColor: undefined,
    defaultMascot: undefined,
  },
  formStyles: {
    id: undefined,
    title: undefined,
    sessionId: '',
    name: '',
    logo: '',
    logoLocation: '',
    nodeAddress: '',
    backgroundColor: undefined,
    anonymous: false,
    elementsColor: '',
    nodeCode: undefined,
    sendButtonText: undefined,
    showNodeCode: false,
    companyID: undefined,
    nodeID: undefined,
    googlePlaceId: undefined,
    withStep: false,
  },
  fontStyles: {
    // fontWeight: 'normal',
    fontFamily: undefined,
    isFontWeightBold: false,
  },
  submitButtonStyles: {
    default: {
      backgroundColor: undefined,
      color: undefined,
    },
    disabled: {
      backgroundColor: undefined,
      color: undefined,
    },
  },
  fieldStyles: new Map<number, { [key: string]: string }>(),
};
