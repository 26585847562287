import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { initialState } from './initialState';
import { fetchFormBySlug } from 'state/thunk';
import { ILanguage } from 'entities/ILanguage';
import { fieldOptionsAdapter } from 'adapters/options/fieldOptionsAdapter';

export const optionSlice = createSlice({
  name: 'option',
  initialState,
  reducers: {
    setPhone: (state, action: PayloadAction<string>) => ({
      ...state,
      phone: action.payload,
    }),
    setSmsVerifyEnabled: (state, action: PayloadAction<boolean>) => ({
      ...state,
      smsVerifyEnabled: action.payload,
    }),
    setToStateSessionId: (state, action: PayloadAction<string>) => ({
      ...state,
      sessionID: action.payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(fetchFormBySlug.fulfilled, (state, action) => {
      // const globalOptions = (
      //   action.payload.nodeForm.form.styles,
      // );
      const fieldOptions = fieldOptionsAdapter(
        action.payload.nodeForm.form.localizations[0].pages,
      );

      // const formStyles = formStylesAdapter(action.payload);

      state.fieldOptions = fieldOptions;
    });
  },
});

const { actions, reducer } = optionSlice;

export const { setPhone, setSmsVerifyEnabled, setToStateSessionId } = actions;

export default reducer;
