import styled from 'styled-components';

export const SubmitButtonStyles = styled.div<{
  bgColor: string | undefined;
  textColor: string | undefined;
  elementsColor: string | undefined;
  fontFamily: string | undefined;
  isFontWeightBold?: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background: ${({ bgColor }) => bgColor || '#eee'};
    box-sizing: border-box;
    border-radius: 40px;
    padding: 0 16px;

    font-weight: ${({ isFontWeightBold }) => `${isFontWeightBold ? '700' : '500'}`};
    font-size: 16px;
    color: ${({ textColor }) => textColor || 'var(--root-white)'};
    font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};
    min-width: 150px;

    &:disabled {
      cursor: not-allowed;
    }
  }

  .statusWrapper {
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .errorWrapper {
    width: 20px;
    height: 20px;
    border-radius: 12px;
    background-color: var(--root-white);
    display: grid;
    place-items: center;

    svg {
      height: 16px;
    }
  }
`;
