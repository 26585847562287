import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapFormStateToFormData } from 'adapters/form/formData';
import { ApiForm } from 'api/entities/ApiForm';
import { formApiV2 } from 'api/formApi';
import { IFormField, IFormState } from 'pages/form/types';
import { NavigateFunction } from 'react-router-dom';

export const fetchFormBySlug = createAsyncThunk<
  ApiForm,
  { slug: string; navigate: NavigateFunction }
>('formV2/fetchFormBySlug', async ({ slug, navigate }, thunkAPI) => {
  const response = await formApiV2.getFormBySlug({
    slug,
  });
  if (response.statusCode !== 200) {
    navigate('/error');
  }
  const data = response.data;
  if (response.data === null) {
    return thunkAPI.rejectWithValue(data);
  }
  return data!;
});

export const submitForm = createAsyncThunk<
  { data: { message: string }; nextPageId: number },
  { formState: IFormState; sessionID: string; nextPageId: number }
>(
  'formV2/submitForm',
  async ({ formState, sessionID, nextPageId }, thunkAPI) => {
    try {
      const formArray: IFormField[] = [];

      Object.keys(formState.extraFields).forEach((key: string) => {
        formArray.push(formState.extraFields[key]);
      });

      const selectedConnectionFieldUrls: { key: string; value: any }[] = Object.values(formState.extraFields)?.find(
        (e) => e.type === 'connection_channel', // @ts-ignore
      )?.botUrls;
      const answers = await mapFormStateToFormData(formArray);
      if (answers) {
        const key = `${
          answers.find((e) => ['telegram', 'viber'].includes(e.value))?.value
        }_url`;
        const url = selectedConnectionFieldUrls?.find(
          (e) => e.key === key,
        )?.value;
        // eslint-disable-next-line no-restricted-globals
        if (url) open(url + sessionID);
        const response = await formApiV2.submitForm({
          answers,
          sessionID,
        });
        return { data: response.data, nextPageId };
      }
      return thunkAPI.rejectWithValue('No answers');
    } catch (e) {
      console.log(e);
      return thunkAPI.rejectWithValue(e);
    }
  },
);
