import React, { FC } from 'react';
import { TIcon } from './TIcon';

export const InterfaceIncrease: FC<TIcon> = React.memo(
  ({ width = 28, height = 28, color = 'var(--root-black)' }) => (
    <svg width={width} height={height} viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1.01077 22.1666L7.46273 5.83325H10.2393L16.6494 22.1666H13.9485L12.3758 17.9456H5.24837L3.66511 22.1666H1.01077ZM6.02023 15.8277H11.6027L8.87533 8.44915H8.76126L6.02023 15.8277ZM21.1711 18.7526V15.0581H17.4766V12.9417H21.1711V9.24724H23.2945V12.9417H26.989V15.0581H23.2945V18.7526H21.1711Z" fill={color} />
    </svg>
  ),
);
