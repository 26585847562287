import styled from 'styled-components';

export const ModalPhoneConfirmationStyles = styled.div<{
  fontFamily: string | undefined;
}>`
  font-family: ${({ fontFamily }) => `${fontFamily || 'e-Ukraine'}, sans-serif`};
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  background: rgba(34, 83, 85, 0.2);
  display: grid;
  place-items: center;
  box-sizing: border-box;

  @media screen and (max-width: 550px) {
    padding: 0 60px;
  }

  .modalBody {
    background: var(--root-white);
    padding: 30px;
    border-radius: 4px;
    width: 100%;
    max-width: 450px;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    p {
      text-align: center;
      margin-top: 10px;
      font-size: 12px;
      opacity: 0.7;
    }

    form {
      width: 100%;
      margin-top: 25px;
    }

    .close {
      position: absolute;
      cursor: pointer;
      right: -15px;
      top: -15px;
    }

    .timer {
      margin-top: 20px;

      p {
        color: var(--root-black);
        text-align: center;
        font-size: 12px;
        font-weight: bold;
      }

      .resend-btn {
        color: var(--root-black);
        font-weight: bold;
        text-align: center;
        font-size: 12px;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .form-field-error {
    font-size: 11px;
    line-height: 16px;
    color: #D92A2A;
  }
`;
