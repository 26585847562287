/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import { FC, useRef, useState } from 'react';
import { DatePickerContainer } from './styles';
import { TDatePickerProps } from './types';
import { useFormSettings } from 'hooks/formSettings/useFormSettings';
import { questionTextByFormType } from 'utils/questionTextByFormType';
import { ErrorMessage, useFormikContext } from 'formik';
import { FormErrorMessage } from 'components/formErrorMessage';
import { useLanguage } from 'hooks/language/useLanguage';
import { format, Locale, parse } from 'date-fns';
// eslint-disable-next-line import/no-extraneous-dependencies
import { enGB, uk, ru } from 'date-fns/locale';
import { CalendarIcon } from 'assets/icons/CalendarIcon';
import { translations } from 'i18n/translations';
import { IFormState } from 'pages/form/types';

export const DatePickerField: FC<TDatePickerProps> = ({
  id,
  value,
  setFieldValue,
  question,
  answerPositionV2,
  name,
  hideError,
  // error,
}) => {
  const { isPageByPageForm } = useFormSettings();
  const { touched, errors, handleBlur } = useFormikContext<IFormState>();
  const { availableLanguages: languages, language } = useLanguage();
  const selectedLanguage = language?.name ?? 'UA';

  const locale: { [key: string]: Locale } = {
    en: enGB,
    ua: uk,
    ru,
  };

  const error = errors.extraFields?.[`${id}str`]?.answer;
  return (
    <DatePickerContainer>
      <div className="dateWrapper">
        {question && (
          <div
            id="question"
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            tabIndex={0}
            aria-label={questionTextByFormType(
              isPageByPageForm,
              answerPositionV2 ?? 0,
              question,
            )}
            className="titleContainer"
            role="region"
          >
            <h1>
              {questionTextByFormType(
                isPageByPageForm,
                answerPositionV2 ?? 0,
                question,
              )}
            </h1>
          </div>
        )}
        <span className="datepicker-toggle">
          <div className="iconContainer">
            <CalendarIcon />
          </div>
          <p className="datepicker-toggle-button">
            {value
              ? format(parse(value, 'yyyy-MM-dd', new Date()), 'dd MMMM yyyy', {
                locale: locale[selectedLanguage.toLowerCase()],
              })
              : translations[selectedLanguage.toUpperCase()].selectDate}
          </p>
          <input
            value={value}
            aria-label="Вибір дати"
            type="date"
            onChange={(e) => setFieldValue(name, e.target.value)}
            className="datepicker-input"
            onBlur={handleBlur}
          />
        </span>
        {hideError && (
          <div className="formErrorContainer">
            {error && (
              <p
                aria-live="polite"
                tabIndex={0}
                aria-label={`Помилка ${error}`}
              >
                {error}
              </p>
            )}
            {/* {touched && (
              <ErrorMessage name={name} component={FormErrorMessage} />
            )} */}
          </div>
        )}
      </div>
    </DatePickerContainer>
  );
};
