import React, { useEffect } from 'react';
import { DocumentStyles } from './DocumentStyles';
import { SquareCheckbox } from '../squareCheckbox/SquareCheckbox';
import { useAppDispatch } from 'state/hooks';
import { setDocumentTermsAgreed } from 'state/slices/formSlice';
import { IDocumentProps } from './types';
import { proxyFile } from 'utils/proxyFile';
import { DevText } from 'components/dev';
import { useFormikContext } from 'formik';
import { IFormState } from 'pages/form/types';

export function Document({
  id,
  field,
  index,
  handleChange,
  setFieldValue,
}: IDocumentProps) {
  const dispatch = useAppDispatch();
  const { errors } = useFormikContext<IFormState>();
  function getValueFromOptions(key: string) {
    return field.options?.find(
      (option: { key: string; value: string }) => option.key === key,
    )?.value;
  }
  // @ts-ignore
  const error = errors?.extraFields?.[`${id}`]?.selected;
  const name = getValueFromOptions('file_description');
  const url = getValueFromOptions('file_url');
  const documentDescription = getValueFromOptions('description');
  const setTermsAgreed = field.options.find((option: any) => option.key === 'set_terms_agreed').value === 'true';

  useEffect(() => {
    if (setTermsAgreed && setFieldValue) {
      setFieldValue(`extraFields[${index}].selected`, setTermsAgreed);
    }
  }, [setTermsAgreed]);
  return (
    <DocumentStyles>
      <div className="documentsContainer">
        <DevText>{id}</DevText>
        {!!documentDescription?.length && (
          <h3 className="documentDescription">
            {documentDescription}
          </h3>
        )}
        <SquareCheckbox
          key={field.id}
          showError
          name={`extraFields[${index}].selected`}
          value={field.selected!}
          onChange={(event) => {
            setFieldValue(
              `extraFields[${index}].selected`,
              event.target.checked,
            );
          }}
        >
          <span style={{ color: 'black' }}>{field.question} </span>
          <a
            href={proxyFile(url, true)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {name}
          </a>
        </SquareCheckbox>
        <div className="formErrorContainer">
          {error && (
            // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
            <p aria-live="polite" tabIndex={0} aria-label={`Помилка ${error}`}>
              {error}
            </p>
          )}
        </div>
      </div>
    </DocumentStyles>
  );
}
