import styled from 'styled-components';

export const TextComponentStyles = styled.h4<{
  font: string | undefined;
  color: string | undefined;
  fontSize: string | undefined;
}>`
  font-family: ${({ font }) => `${font}, sans-serif` || 'e-Ukraine, sans-serif'};
  font-size: ${({ fontSize }) => `${fontSize}px` || '10px'};
  color: ${({ color }) => `${color}` || 'var(--root-black)'};
  font-weight: 400;
`;
