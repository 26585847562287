import styled from 'styled-components';

export const Container = styled.div`
  background-color: red;
  .languageSelect {
    padding-top: 14px;
    position: relative;
    color: var(--root-black);
  }

  .selectedLanguage {
    color: var(--root-black);
    font-size: 20px;
    font-weight: 600;
    display: flex;
    gap: 5px;
    align-items: center;

    img {
      max-width: 25px;
    }

    &.opened {
      svg {
        transform: rotate(-180deg);
        transition: all 0.3s;
      }
    }

    svg {
      margin-left: 8px;
      margin-bottom: 2px;
      transition: all 0.3s;
    }
  }

  .logoImage {
    .logo {
      max-height: 35px;
      object-fit: contain;
    }
  }

  .languages {
    width: auto;
    min-width: 70px;
    position: absolute;
    top: 35px;
    left: -4px;
    background-color: var(--root-white);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    padding: 4px 0;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);

    button {
      height: 28px;
      padding: 0 12px;
      display: flex;
      gap: 5px;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      color: var(--root-black);

      img {
        max-width: 20px;
      }
    }
  }
`;
